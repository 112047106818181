import { useState, useEffect } from 'react';
import {createContext} from 'react';
import {onAuthStateChangedListener} from '../firebase/firebase.utils'


//actual value to access
export const UserContext = createContext({
    currentUser: null,
    setCurrentUser: () => null,
});

export const UserProvider = ({children}) => {
    const [authInit, setAuthInit] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const value = {currentUser, setCurrentUser};

    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener((user)=>{
            setCurrentUser(user);

            // set the authInit flag once the first time this auth event is called
            // this lets us know that the firebase auth has been init for the first time.
            if (!authInit) {
                setAuthInit(true);
            }
        });
        return unsubscribe;
    }, [authInit, setAuthInit]);

    // if the firebase auth has not been init yet, then do not render the UserContext children
    // since the firebase auth init is async, this will prevent them triggering redirect logic
    // before the firebase auth session has been restored for the first time.
    if (!authInit) { return null; }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

