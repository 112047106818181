import { initializeApp } from "firebase/app";


import {
    browserLocalPersistence,
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    setPersistence,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
} from "firebase/auth";

// see .env.example for firebase config env vars
const firebaseConfig = {

    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",

    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",

    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",

    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",

    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",

    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",

    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ""

};

// Initialize Firebase

initializeApp(firebaseConfig);

export const auth = getAuth();

const authPersistence = process.env.REACT_APP_FIREBASE_AUTH_PERSISTENCE;
const persistenceType = (authPersistence === 'SESSION')? browserSessionPersistence: (authPersistence === 'LOCAL')? browserLocalPersistence: null;
if (persistenceType) {
    setPersistence(auth, persistenceType);
}

export const registerWithEmailAndPassword = async(email, password, passwordConfirm) => {
    if (!email || !password || !passwordConfirm) return;
    return await createUserWithEmailAndPassword(auth, email, password, passwordConfirm);
    
};

export const signInAuthUserWithEmailAndPassword = async(email, password) => {
    if (!email || !password) return;
    return await signInWithEmailAndPassword(auth, email, password)

};

export const sendUserValidationEmail = async(email) => {
    if (!email) return;
    return await sendEmailVerification(email)
}

export const sendResetEmail = async(email) => {
    if (!email) return;
    return await sendPasswordResetEmail(auth, email)
}

export const signOutUser = () => signOut(auth);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);
