import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './components/register/register'; // Import the Register component
import Login from './components/login/login';
import Home from './components/home/home' // Import the home component
import ProtectedRoute from "./components/protectedRoute/protectedRoute";
import ResetPassword from './components/resetPassword/resetPassword';


import './App.css';


function App() {
  
  
  return (
    
      <Routes>
        <Route exact path="/" element={<Login/>} />  
        <Route exact path="/register" element={<Register/>} />
        <Route exact path="/resetPassword" element={<ResetPassword/>} />
        <Route exact path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      </Routes>
    
  );
}

export default App;
