import { useState} from "react";
import { sendResetEmail} from "../../firebase/firebase.utils";
import { Link } from "react-router-dom";


export default function ResetPassword() {
    const [resetEmail, setResetEmail] = useState("");
    const [errorShow, setErrorShow] = useState("");
    const [message, setMessage] = useState("");
    
    

    async function reset(e) {
        e.preventDefault();
        try {
            
            await sendResetEmail(resetEmail)
            setMessage("Password Reset Email Sent")
            }
           catch (error){
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorMessage + errorCode);
                setErrorShow("No account associated with this email");
            }
    }


    return (
    <div className='errorcontainer'>
      {!errorShow ? <div> </div> : <div className="error">{errorShow}</div>}
      {!message ? <div> </div> : <div className="success">{message}</div>}
      <div className="loginbody">
      
        <div className="logincontainer">
          <div className="loginheader">
            <h2>Reset Password</h2>
          </div>
          <form className="loginform-group" onSubmit={(e) => reset(e)}>
            <input type="text" placeholder="Enter Account Email" onChange={(e) => setResetEmail(e.target.value)} required autoFocus />
            <button type="submit">Reset</button>
          </form>
          <p>Back to <Link to="/">Login</Link></p>
          <div className="loginfooter">
            <p>
              Don't have an account? <Link to="/register">Sign up</Link> 
            </p>
          </div>
        </div>
      </div>
    </div>
    );
}