import React, { useState } from 'react';
import Navbar from '../navbar/navbar.jsx'; // Import the Navbar component
import LoadingModal from '../loadingModal/loadingModal';
import './home.css'; // Import your stylesheet here

const apiUrl = process.env.REACT_APP_SOW_API

const FileItem = ({ fileName, onRemove, onContractorsClick }) => {
  return (
    <div className="file-item">
      <span>{fileName}</span>
      <div className="buttons-container">
        <button className="contractors-button" onClick={onContractorsClick}>Contractors</button>
        <button onClick={() => onRemove(fileName)}>Delete</button>  
      </div>
    </div>
  );
};

const Home = () => {
  const [fileNameList, setFileNameList] = useState([]);
  const [subcontractors, setSubcontractors] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipBlob, setZipBlob] = useState(null);
  const [showDownload, setShowDownload] = useState(null)

  const handleDrop = (e) => {
    e.preventDefault();

    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleFiles = (files) => {
    const file = files[0]; // Assuming only one file is dropped

    // Update the file list state
    setFileNameList([file.name]);

    // Show the modal and set the selected file
    setShowModal(true);
    setSelectedFileName(file.name);
    setSelectedFile(file);
  };

  const removeFile = (fileName) => {
    // Update the file list state
    setFileNameList([]);

    // Hide the modal
    setShowModal(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const selectAllCheckboxes = () => {
    setSubcontractors(subcontractorList);
  };
  
  const clearAllCheckboxes = () => {
    setSubcontractors([]);
  };

  const handleCheckboxChange = (sub) => {
    if (subcontractors.includes(sub)) {
      // Remove from the selected subcontractors list
      setSubcontractors((prevSubcontractors) => prevSubcontractors.filter((s) => s !== sub));
    } else {
      // Add to the selected subcontractors list
      setSubcontractors((prevSubcontractors) => [...prevSubcontractors, sub]);
    }
  };

  const generateSow = () => {
    // Create a FormData object with the file and selected subcontractors
    const formData = new FormData();
    formData.append('excelFile', selectedFile);
    formData.append('subs', JSON.stringify(subcontractors));

    // Set loading to true while waiting for the response
    setLoading(true);
    setShowModal(false);

    // Make a POST request to api/upload with the FormData
    fetch(`https://${apiUrl}/upload`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Save the zip file blob for later use
        setZipBlob(blob);
        setShowDownload(true)
        setLoading(false);

        // Close the modal
        
      })
      .catch((error) => {
        console.error('Error generating SOW:', error);
        setLoading(false);
        // Handle errors
      });
  };

  const downloadZipFile = () => {
    if (zipBlob) {
      // Create a download link and trigger the download
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(zipBlob);
      downloadLink.href = url;
      downloadLink.download = 'SOW-Documents.zip';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Clean up the URL.createObjectURL
      window.URL.revokeObjectURL(url);
    }
  };

  // List of subcontractors
  const subcontractorList = [
    "Demolition",
    "Shoring",
    "Abatement",
    "Earthwork",
    "Unit Pavers",
    "Safety Surfacing",
    "Fencing",
    "Site Improvements",
    "Landscape",
    "Concrete Formwork",
    "Concrete Reinforcing",
    "Concrete Materials",
    "Concrete Finish",
    "Concrete Underlayment",
    "Precast Concrete",
    "Masonry",
    "Structural Steel",
    "Miscellaneous Metals",
    "Rough Carpentry - LABOR",
    "Rough Carpentry - MATERIAL",
    "Finish Carpentry - Labor",
    "Finish Carpentry - Material",
    "Arch Millwork",
    "Waterproof Damproof",
    "Insulation",
    "E.I.F.S.",
    "Spray Fireproofing",
    "Firestopping",
    "Metal Panels",
    "Roofing",
    "Caulking Sealants",
    "Doors-Frames-Hardware",
    "Overhead Doors",
    "Aluminum Entrances",
    "Aluminum Windows",
    "Glass - Glazing",
    "Curtain Wall",
    "Gypsum Wall Board",
    "Ceramic Tile",
    "Acoustical Ceilings",
    "Wood Flooring",
    "Resilient Floor",
    "Resinous Flooring",
    "Carpet",
    "Painting",
    "Specialties",
    "Access Flooring",
    "Signage",
    "Dock Equipment",
    "Food Service",
    "Appliances",
    "Casework",
    "Entrance Mats",
    "Window Treatment",
    "Furniture",
    "Special Construction",
    "Elevator",
    "Fire Protection",
    "Gaseous Suppression",
    "Plumbing",
    "HVAC",
    "Electrical",
    "Tel Data",
    "Security"
  ];

  return (
    <div>
      <Navbar />

      <div className="container">
        <p>Please upload a bidsheet to generate scope of work documents.</p>

        {/* Drag-and-drop area */}
        <div
          className="drag-drop-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <p>Drag & Drop a file here or click to upload.</p>
          <input
            type="file"
            accept=".xlsx, .xls, .xlsm"
            id="uploadInput"
            style={{ display: 'none' }}
            onChange={(e) => handleFiles(e.target.files)}
          />
          <button type="button" onClick={() => document.getElementById('uploadInput').click()}>
            Upload
          </button>
        </div>

        <div className="file-list">
          {fileNameList.map((fileName) => (
            <FileItem key={fileName} fileName={fileName} onRemove={removeFile} onContractorsClick={toggleModal} />
          ))}
        </div>

        {showModal && (
			<div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <h2>Selected File: {selectedFileName}</h2>
            <h3>Select Subcontractors</h3>
      
            {/* Buttons to Select All and Clear All */}
            <div className="select-buttons">
              <button  onClick={selectAllCheckboxes} className="green-button">
                Select All
              </button>
              <button  onClick={clearAllCheckboxes} className="red-button">
                Clear All
              </button>
            </div>
      
            {/* Columns for Subcontractors */}
            <div className="subcontractor-columns-container">
              {subcontractorList.map((sub) => (
                <label key={sub} className="subcontractor-checkbox">
                  <input
                    type="checkbox"
                    checked={subcontractors.includes(sub)}
                    onChange={() => handleCheckboxChange(sub)}
                  />
                  {sub}
                </label>
              ))}
            </div>
            
            <button type="button" className="modal-button" onClick={generateSow}>
              Submit
            </button>
          </div>
        </div>
    </div>

        )}
        {loading && <LoadingModal />}
        {/* Download All Files Button */}
        {showDownload && fileNameList.length > 0 &&(
          <div className="download-zip-button">
            <button type="button" onClick={downloadZipFile}>
              Download
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
